<template lang="html">
  <div class="page" id="home">
    <section id="hero">
      <div class="wrapper">
        <img src="img/logo-otl.jpg" alt=""/>
        <div class="bar">
          <span class="circle"></span>
        </div>
      </div>
    </section>
    <section id="contact">
      <div class="wrapper">
        <h3>FALE CONOSCO</h3>
        <p>Entre em contato conosco pelo formulário abaixo.</p>
        <form @submit.prevent="send" id="contactForm">
          <input type="text" value="" name="" placeholder="Nome" v-model="name" id="" required/>
          <input type="email" value="" name="" placeholder="Email" v-model="email" id="" required/>
          <input type="text" value="" name="" placeholder="Assunto" v-model="subject" id="" required/>
          <textarea type="text" value="" name="" placeholder="Mensagem" v-model="message" id="" required/>
          <button type="submit">ENVIAR</button>
        </form>
        <div class="bar">
          <span class="circle"></span>
        </div>
      </div>
    </section>
    <section id="about">
      <div class="wrapper">
        <h3>SOBRE A EMPRESA</h3>
        <p>Empresa de Engenharia  especializada  nos setores Elétrico, Civil e de Segurança do Trabalho , bem como na prestação de serviços nas referidas áreas. Contamos com profissionais qualificados e parceiros dedicados que trabalham com compromisso para levar qualidade e satisfação aos nossos clientes.</p>
      </div>
    </section>
    <section id="services">
      <div class="wrapper">
        <h3>SERVIÇOS</h3>
        <p>Usinas Fotovoltaicas | Grupos Geradores | Eletrificação Rural  | Spda | Laudos Técnicos | Eficiência Energética</p>
      </div>
    </section>
    <section id="address">
      <div class="wrapper">
        <div class="bar">
          <span class="circle"></span>
        </div>
        <map-marker/>
          <h5>OTL Engenharia</h5>
          <p>Av. Itabuna, 791-B, Centro Ilhéus Bahia</p>
          <p><strong>Telefone:</strong> (73) 99168 5580 / 98810 0970</p>
          <p><strong>Email:</strong> contato@otlengenharia.com.br</p>
      </div>
    </section>
    <section id="footer">
      <div class="wrapper">
        <hr>
        <small>&copy; Copyright 2018, OTL Engenharia. Todos os direitos reservados.</small>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mapMarker from '../../public/img/icons/map-marker.svg'
import _ from 'lodash'
export default {
  name: 'home',
  data () {
    return {
      name: null,
      email: null,
      subject: null,
      message: null
    }
  },
  computed: {
    ...mapGetters([
      'saleProducts'
    ]),
    text: {
      get () {
        return this.$store.getters.text
      },
      set: _.debounce(function (newVal) {
        this.changeText(newVal)
      }, 300)
    }
  },
  mounted () {},
  methods: {
    ...mapActions([
      'changeText',
      'reducePrice'
    ]),
    send () {
      let form = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }
      this.$http.post('https://otlengenharia.com.br/form.php', form).then(response => {
        this.$toasted.success('Mensagem enviada com sucesso!', {
          onComplete: () => {
            this.name = this.email = this.subject = this.message = null
          }
        })
      }).catch((err) => {
        console.log(err)
        this.$toasted.error('Ocorreu um erro, tente novamente!', {
          onComplete: () => {
            this.name = this.email = this.subject = this.message = null
          }
        })
      })
    }
  },
  components: {
    mapMarker
  }
}
</script>
