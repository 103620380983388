<template lang="html">
  <div class="page" id="dashboard">
    <div class="wrapper">
      <h1>Hello from Dashboard</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dashboard',
  mounted () {},
  components: {
  }
}
</script>
